<template>
    <div class="main-box">
        <a-form ref="formRefGdinfo" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="con-box">
                <div class="title">
                    <p>企业诊断-股东信息</p>
                </div>
                <div class="form-box">
                    <div class="gd-item" v-for="(gItem, gi) in gudongList" :key="gi">
                        <a-form-item label="股东姓名">
                            <a-input  v-model:value="gItem.gName" placeholder="请输入股东姓名" />
                        </a-form-item>
                        <a-form-item label="股东身份证号">
                            <a-input  v-model:value="gItem.gIdCard" placeholder="请输入股东身份证号" />
                        </a-form-item>
                        <a-form-item label="股东手机号码">
                            <a-input  v-model:value="gItem.gPhone" placeholder="请输入股东手机号码" />
                        </a-form-item>
                        <a-form-item class="gd-item-a" :style="{ visibility: !gItem.gdid ? 'visible' : 'hidden' }">
                            <PlusOutlined
                                style="font-size:18px"
                                class="dynamic-delete-button"
                                @click="addGDinfo(gi)"
                            />
                        </a-form-item>
                        <a-form-item class="gd-item-a">
                            <a-popconfirm
                                title="确定要删除此条记录吗?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="removeGDinfo(gItem)"
                                @cancel="cancel"
                            >
                                <MinusCircleOutlined
                                    style="font-size:18px"
                                    class="dynamic-delete-button"
                                />
                            </a-popconfirm>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div class="con-box">
                <div class="title">
                    <p>企业诊断-高管信息</p>
                </div>
                <div class="form-box">
                    <div class="gd-item" v-for="(ggItem, ggi) in gaoguanList" :key="ggi">
                        <a-form-item label="高管姓名">
                            <a-input v-model:value="ggItem.ggName" placeholder="请输入高管姓名" />
                        </a-form-item>
                        <a-form-item label="高管身份证号">
                            <a-input v-model:value="ggItem.ggIdCard" placeholder="请输入高管身份证号" />
                        </a-form-item>
                        <a-form-item label="高管手机号码">
                            <a-input v-model:value="ggItem.ggPhone" placeholder="请输入高管手机号码" />
                        </a-form-item>
                        <a-form-item class="gd-item-a" :style="{ visibility: !ggItem.ggid ? 'visible' : 'hidden' }">
                            <PlusOutlined
                                style="font-size:18px"
                                class="dynamic-delete-button"
                                @click="addGGinfo(ggi)"
                            />
                        </a-form-item>
                        <a-form-item class="gd-item-a">
                            <a-popconfirm
                                title="确定要删除此条记录吗?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="removeGGinfo(ggItem)"
                                @cancel="cancel"
                            >
                                <MinusCircleOutlined
                                    style="font-size:18px"
                                    class="dynamic-delete-button"
                                />
                            </a-popconfirm>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div class="con-box">
                <div class="title">
                    <p>企业诊断-财税信息</p>
                </div>
                <div class="form-box">
                    <div class="gd-item gd-items">
                        <a-form-item label="年度">
                            <a-select v-model:value="nd" :disabled="editStatus ? true : false" placeholder="请选择年度">
                                <a-select-option v-for="(nItem, ni) in niandu" :key="ni" :value="nItem.val">{{nItem.name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="企业所得税应纳税额(元)">
                            <a-input v-model:value="shangnianqiyesuodeshuiyingnashuie" placeholder="请输入企业所得税应纳税额(元)" />
                        </a-form-item>
                        <a-form-item label="净利润(元)">
                            <a-input v-model:value="shangnianjinglirun" placeholder="请输入净利润(元)" />
                        </a-form-item>
                        <a-form-item label="增值税应纳税额(元)">
                            <a-input v-model:value="shangnianzengzhishuiyingnashuie" placeholder="请输入增值税应纳税额(元)" />
                        </a-form-item>
                        <a-form-item label="经营现金净流量(元)">
                            <a-input v-model:value="shangnianjingyingxianjinjingliuliang" placeholder="请输入经营现金净流量(元)" />
                        </a-form-item>
                        <a-form-item label="营业收入(元)">
                            <a-input v-model:value="shangnianyingyeshouru" placeholder="请输入营业收入(元)" />
                        </a-form-item>
                        <a-form-item label="资产总计(元)">
                            <a-input v-model:value="shangnianzichanzongji" placeholder="请输入资产总计(元)" />
                        </a-form-item>
                        <a-form-item label="应收账款(元)" v-if="qiyeType === 'fw'">
                            <a-input v-model:value="shangnianyingshouzhangkuan" placeholder="请输入应收账款(元)" />
                        </a-form-item>
                        <a-form-item label="应纳税额(元)" v-if="qiyeType === 'xw'">
                            <a-input v-model:value="shangnianyingnashuie" placeholder="请输入应纳税额(元)" />
                        </a-form-item>
                        <a-form-item label="负债合计(元)" v-if="qiyeType === 'zhizao' || qiyeType === 'fw'">
                            <a-input v-model:value="shangnianfuzhaiheji" placeholder="请输入负债合计(元)" />
                        </a-form-item>
                        <a-form-item label="财务费用(元)" v-if="qiyeType === 'xw'">
                            <a-input v-model:value="shangniancaiwufeiyong" placeholder="请输入财务费用(元)" />
                        </a-form-item>
                        <a-form-item label="进项发票中专票金额总计(元)" v-if="qiyeType === 'xw'">
                            <a-input v-model:value="shangnianjinxiangfapiaozhongzhuanpiaojinezongji" placeholder="请输入进项发票中专票金额总计(元)" />
                        </a-form-item>
                        <a-form-item label="进项发票金额总计(元)" v-if="qiyeType === 'xw'">
                            <a-input v-model:value="shangnianjinxiangfapiaojinezongji" placeholder="请输入进项发票金额总计(元)" />
                        </a-form-item>
                        <a-form-item label="销售收入(元)" v-if="qiyeType === 'zhizao' || qiyeType === 'fw'">
                            <a-input v-model:value="shangnianxiaoshoushouru" placeholder="请输入销售收入(元)" />
                        </a-form-item>
                        <a-form-item label="销售费用(元)" v-if="qiyeType === 'zhizao' || qiyeType === 'xw'">
                            <a-input v-model:value="shangnianxiaoshoufeiyong" placeholder="请输入销售费用(元)" />
                        </a-form-item>
                        <a-form-item label="销项发票中专票金额总计(元)" v-if="qiyeType === 'xw' || qiyeType === 'fw'">
                            <a-input v-model:value="shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji" placeholder="请输入销项发票中专票金额总计(元)" />
                        </a-form-item>
                        <a-form-item label="销项发票金额总计(元)" v-if="qiyeType === 'xw' || qiyeType === 'fw'">
                            <a-input v-model:value="shangnianxiaoxiangfapiaojinezongji" placeholder="请输入销项发票金额总计(元)" />
                        </a-form-item>
                        <a-form-item label="企业成立年份" v-if="qiyeType === 'zhizao'">
<!--                            <a-input v-model:value="qiyechenglinianfen" placeholder="请输入企业成立年份" />-->
                            <a-date-picker
                                format="YYYY"
                                valueFormat="YYYY"
                                v-model:value="qiyechenglinianfen" picker="year"/>
                        </a-form-item>
                        <a-form-item label="前年应收账款(元)" v-if="qiyeType === 'fw'">
                            <a-input v-model:value="qiannianyingshouzhangkuan" placeholder="请输入前年应收账款(元)" />
                        </a-form-item>
                        <a-form-item label="前年应纳税额(元)" v-if="qiyeType === 'xw'">
                            <a-input v-model:value="qiannianyingnashuie" placeholder="请输入前年应纳税额(元)" />
                        </a-form-item>
                        <a-form-item label="前年销售收入(元)" v-if="qiyeType === 'zhizao'">
                            <a-input v-model:value="qiannianxiaoshoushouru" placeholder="请输入前年销售收入（元）" />
                        </a-form-item>
                        <a-form-item label="注册资本（元）" v-if="qiyeType === 'fw'">
                            <a-input v-model:value="zhuceziben" placeholder="请输入注册资本（元）" />
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div class="con-box">
                <div class="btn-items">
                     <a-button @click="onCaiShui">
                        保存当前
                    </a-button>
                     <a-button style="margin-left:30px" @click="$router.push({path: '/PersonalLayout/DiagnosisHistory'})">
                        诊断历史
                    </a-button>
                     <a-button style="margin-left:30px" @click="onSubmit" type="primary" v-if="showBtn">
                        确认提交
                    </a-button>
                </div>
            </div>
        </a-form>
    </div>
</template>

<script>
import {
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons-vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        MinusCircleOutlined,
        PlusOutlined,
    },
    computed: {
        ...mapGetters(['orgId', 'isAuth', 'loginType', 'orgType', 'orgName'])
    },
    setup() {
        return {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 14,
            }
        }
    },
    data () {
        return {
            showBtn: false,
            gudongList: [{gName:'',gIdCard:'',gPhone:''}],
            gaoguanList: [],
            shangnianqiyesuodeshuiyingnashuie: '',
            shangnianjinglirun: '',
            shangnianzengzhishuiyingnashuie: '',
            shangnianjingyingxianjinjingliuliang: '',
            shangnianyingyeshouru: '',
            shangnianzichanzongji: '',
            shangnianyingnashuie: '',
            shangnianfuzhaiheji: '',
            shangniancaiwufeiyong: '',
            shangnianjinxiangfapiaozhongzhuanpiaojinezongji: '',
            shangnianjinxiangfapiaojinezongji: '',
            shangnianxiaoshoushouru: '',
            shangnianxiaoshoufeiyong: '',
            shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji: '',
            shangnianxiaoxiangfapiaojinezongji: '',
            qiyechenglinianfen: '',
            qiannianyingshouzhangkuan: '',
            qiannianyingnashuie: '',
            qiannianxiaoshoushouru: '',
            zhuceziben: '',
            niandu: [
                {
                    name: new Date().getFullYear() - 1 + '年度',
                    val: new Date().getFullYear() - 1
                },
                {
                    name: new Date().getFullYear() - 2 + '年度',
                    val: new Date().getFullYear() - 2
                },
                {
                    name: new Date().getFullYear() - 3 + '年度',
                    val: new Date().getFullYear() - 3
                }
            ],
            nd: undefined,
            shangnianyingshouzhangkuan: '',
            id: '',
            editStatus: false,
            qiyeType: ''
        }
    },
    methods: {
        // 删除高管信息
        removeGGinfo (item) {
            if (!item.ggid) {
                this.$message.info('不能删除此条记录！')
                return false
            }
            this.$store.dispatch('deletePerson', { ENTERPERSON_ID: item.ggid, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getPersonList()
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        // 删除股东信息
        removeGDinfo (item) {
            if (!item.gdid) {
                this.$message.info('不能删除此条记录！')
                return false
            }
            this.$store.dispatch('deletePerson', { ENTERPERSON_ID: item.gdid, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getPersonList()
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        // 添加股东信息
        addGDinfo (key) {
            if (this.gudongList.length != 0) {
                if (this.gudongList[key].gName === '') {
                    this.$message.info('股东姓名不能为空')
                    return false
                } else if (this.gudongList[key].gIdCard === '') {
                    this.$message.info('股东身份证号不能为空')
                    return false
                } else if (this.gudongList[key].gPhone === '') {
                    this.$message.info('股东手机号不能为空')
                    return false
                }
                //验证手机号
              if(!(/^1[3456789]\d{9}$/.test(this.gudongList[key].gPhone))){
                this.$message.info('手机号格式错误')
                return false
              }
              //========================== 验证身份证号 start =================================//
             let a = this.gudongList[key].gIdCard
                var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
                if (!a || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(a)) {
                  this.$message.info('身份证号格式错误')
                  return false
                } else if (!city[a.substr(0, 2)]) {
                  this.$message.info('地址编码错误')
                  return false
                } else {
                  // 18位身份证需要验证最后一位校验位
                  if (a.length === 18) {
                    a = a.split('')
                    // ∑(ai×Wi)(mod 11)
                    // 加权因子
                    var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                    // 校验位
                    var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
                    var sum = 0
                    var ai = 0
                    var wi = 0
                    for (var i = 0; i < 17; i++) {
                      ai = a[i]
                      wi = factor[i]
                      sum += ai * wi
                    }
                    var last = parity[sum % 11];
                    if (parity[sum % 11] != a[17]) {
                      this.$message.info('校验位错误')
                      return false

                    }
                  }
                }
            //========================== 验证身份证号 end ======================================================//
                this.$store.dispatch('addPersonList', {
                    PERSONPROPER: '股东',
                    PERSONNAME: this.gudongList[key].gName,
                    PERSONCODE: this.gudongList[key].gIdCard,
                    PHONE: this.gudongList[key].gPhone,
                    tm: new Date().getTime(),
                    ENTERPRISE_ID: this.orgId
                }).then(res => {
                    if (res.result === 'success') {
                        this.$message.success('添加成功！')
                        this.getPersonList()
                    } else {
                        this.$message.error('第' + (parseInt(key) + 1) + '条股东信息添加失败！')
                    }
                })
            }
        },
        // 添加高管信息
        addGGinfo (key) {
            if (this.gaoguanList.length != 0) {
                if (this.gaoguanList[key].ggName === '') {
                    this.$message.info('高管姓名不能为空')
                    return false
                } else if (this.gaoguanList[key].ggIdCard === '') {
                    this.$message.info('高管身份证号不能为空')
                    return false
                } else if (this.gaoguanList[key].ggPhone === '') {
                    this.$message.info('高管手机号不能为空')
                    return false
                }
              //验证手机号
              if(!(/^1[3456789]\d{9}$/.test(this.gaoguanList[key].ggPhone))){
                this.$message.info('手机号格式错误')
                return false
              }
              //========================== 验证身份证号 start =================================//
              let a = this.gaoguanList[key].ggIdCard
              var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
              if (!a || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(a)) {
                this.$message.info('身份证号格式错误')
                return false
              } else if (!city[a.substr(0, 2)]) {
                this.$message.info('地址编码错误')
                return false
              } else {
                // 18位身份证需要验证最后一位校验位
                if (a.length === 18) {
                  a = a.split('')
                  // ∑(ai×Wi)(mod 11)
                  // 加权因子
                  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
                  // 校验位
                  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
                  var sum = 0
                  var ai = 0
                  var wi = 0
                  for (var i = 0; i < 17; i++) {
                    ai = a[i]
                    wi = factor[i]
                    sum += ai * wi
                  }
                  var last = parity[sum % 11];
                  if (parity[sum % 11] != a[17]) {
                    this.$message.info('校验位错误')
                    return false

                  }
                }
              }
              //========================== 验证身份证号 end =================================//
                this.$store.dispatch('addPersonList', {
                    PERSONPROPER: '高管',
                    PERSONNAME: this.gaoguanList[key].ggName,
                    PERSONCODE: this.gaoguanList[key].ggIdCard,
                    PHONE: this.gaoguanList[key].ggPhone,
                    tm: new Date().getTime(),
                    ENTERPRISE_ID: this.orgId
                }).then(res => {
                    if (res.result === 'success') {
                        this.$message.success('添加成功！')
                        this.getPersonList()
                    } else {
                        this.$message.error('第' + (parseInt(key) + 1) + '条高管信息添加失败！')
                    }
                })
            }
        },
        // 企业人员信息-查询
        getPersonList () {
            this.gudongList = []
            this.gaoguanList = []
            this.$store.dispatch('getPersonList', { showCount: -1, currentPage: 1, ENTERPRISE_ID: this.orgId }).then(res => {
                if (res.result === 'success') {
                    if (res.varList.length != 0) {
                        let datas = res.varList
                        for (let i = 0; i < datas.length; i++) {
                            if (datas[i].PERSONPROPER === '股东') {
                                this.gudongList.push({
                                    gName: datas[i].PERSONNAME,
                                    gIdCard: datas[i].PERSONCODE,
                                    gPhone: datas[i].PHONE,
                                    gdid: datas[i].ENTERPERSON_ID
                                })
                            } else {
                                this.gaoguanList.push({
                                    ggid: datas[i].ENTERPERSON_ID,
                                    ggName: datas[i].PERSONNAME,
                                    ggIdCard: datas[i].PERSONCODE,
                                    ggPhone: datas[i].PHONE
                                })
                            }
                        }
                        this.gudongList.push({
                            gName: '',
                            gIdCard: '',
                            gPhone: ''
                        })
                        this.gaoguanList.push({
                            ggName: '',
                            ggIdCard: '',
                            ggPhone: ''
                        })
                    }else{
                        this.gudongList.push({
                            gName: '',
                            gIdCard: '',
                            gPhone: ''
                        })
                        this.gaoguanList.push({
                            ggName: '',
                            ggIdCard: '',
                            ggPhone: ''
                        })
                    }
                } else {
                    this.$message.error('服务器异常')
                }
            })
        },
        // 获取企业信息
        getOrgDetail () {
            this.$store.dispatch('getOrgDetail', { showCount: -1, currentPage: 1, ENTERPRISE_ID: this.orgId }).then(res => {
                if (res.result === 'success') {
                    this.qiyeType = res.pd.ENTERPRISE_TYPE
                } else {
                    this.$message.error('系统异常，获取企业信息失败，请稍后重试！')
                }
            })
        },
        // 添加财税信息
        onCaiShui () {
            let isValid = this.getIsValid()
            if (isValid) {
                if (this.editStatus) {
                    this.onEdit()
                } else {
                    this.onAdd()
                }
            }
        },
        onEdit () {
            this.$store.dispatch('editCaiShui', {
                ENTERCAISHUI_ID: this.id,
                SNQYSDSYNSE: this.shangnianqiyesuodeshuiyingnashuie ? this.shangnianqiyesuodeshuiyingnashuie : null,
                SNJLR: this.shangnianjinglirun ? this.shangnianjinglirun : null,
                SNZZSYNSE: this.shangnianzengzhishuiyingnashuie ? this.shangnianzengzhishuiyingnashuie : null,
                SNYSZK: this.shangnianyingshouzhangkuan ? this.shangnianyingshouzhangkuan : null,
                SHYNSE: this.shangnianyingnashuie ? this.shangnianyingnashuie : null,
                SNJYXJJLL: this.shangnianjingyingxianjinjingliuliang ? this.shangnianjingyingxianjinjingliuliang : null,
                SNYYSR: this.shangnianyingyeshouru ? this.shangnianyingyeshouru : null,
                SNFZHJ: this.shangnianfuzhaiheji ? this.shangnianfuzhaiheji : null,
                SNCWFY: this.shangniancaiwufeiyong ? this.shangniancaiwufeiyong : null,
                SNZCZJ: this.shangnianzichanzongji ? this.shangnianzichanzongji : null,
                SNJXFPZZPJEZJ: this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji ? this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji : null,
                SNJXFPJEZJ: this.shangnianjinxiangfapiaojinezongji ? this.shangnianjinxiangfapiaojinezongji : null,
                SNXSSR: this.shangnianxiaoshoushouru ? this.shangnianxiaoshoushouru : null,
                SNXSFY: this.shangnianxiaoshoufeiyong ? this.shangnianxiaoshoufeiyong : null,
                SNXXFPZZPJEZJ: this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji ? this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji : null,
                SNXXFPJEZJ: this.shangnianxiaoxiangfapiaojinezongji ? this.shangnianxiaoxiangfapiaojinezongji : null,
                QICLNF: this.qiyechenglinianfen ? this.qiyechenglinianfen : null,
                QNYSZK: this.qiannianyingshouzhangkuan ? this.qiannianyingshouzhangkuan : null,
                QNYNSE: this.qiannianyingnashuie ? this.qiannianyingnashuie : null,
                QNXSSR: this.qiannianxiaoshoushouru ? this.qiannianxiaoshoushouru : null,
                ZCZB: this.zhuceziben ? this.zhuceziben : null,
                YEAR: this.nd ? this.nd : null,
                tm: new Date().getTime(),
                ENTERPRISE_ID: this.orgId,
                ENTERPRISE_TYPE: this.orgType,
                ENTERPRISE_NAME: this.orgName
            }).then(res => {
            debugger
                if (res.result === 'success') {
                    this.showBtn = true
                    this.$message.success('保存成功')
                }else if (res.result === 'repeat') {
                  this.$message.success('数据已提交无法修改')
                }else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        },
        onAdd () {
            this.$store.dispatch('addCaiShui', {
                SNQYSDSYNSE: this.shangnianqiyesuodeshuiyingnashuie ? this.shangnianqiyesuodeshuiyingnashuie : null,
                SNJLR: this.shangnianjinglirun ? this.shangnianjinglirun : null,
                SNZZSYNSE: this.shangnianzengzhishuiyingnashuie ? this.shangnianzengzhishuiyingnashuie : null,
                SNYSZK: this.shangnianyingshouzhangkuan ? this.shangnianyingshouzhangkuan : null,
                SHYNSE: this.shangnianyingnashuie ? this.shangnianyingnashuie : null,
                SNJYXJJLL: this.shangnianjingyingxianjinjingliuliang ? this.shangnianjingyingxianjinjingliuliang : null,
                SNYYSR: this.shangnianyingyeshouru ? this.shangnianyingyeshouru : null,
                SNFZHJ: this.shangnianfuzhaiheji ? this.shangnianfuzhaiheji : null,
                SNCWFY: this.shangniancaiwufeiyong ? this.shangniancaiwufeiyong : null,
                SNZCZJ: this.shangnianzichanzongji ? this.shangnianzichanzongji : null,
                SNJXFPZZPJEZJ: this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji ? this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji : null,
                SNJXFPJEZJ: this.shangnianjinxiangfapiaojinezongji ? this.shangnianjinxiangfapiaojinezongji : null,
                SNXSSR: this.shangnianxiaoshoushouru ? this.shangnianxiaoshoushouru : null,
                SNXSFY: this.shangnianxiaoshoufeiyong ? this.shangnianxiaoshoufeiyong : null,
                SNXXFPZZPJEZJ: this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji ? this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji : null,
                SNXXFPJEZJ: this.shangnianxiaoxiangfapiaojinezongji ? this.shangnianxiaoxiangfapiaojinezongji : null,
                QICLNF: this.qiyechenglinianfen ? this.qiyechenglinianfen : null,
                QNYSZK: this.qiannianyingshouzhangkuan ? this.qiannianyingshouzhangkuan : null,
                QNYNSE: this.qiannianyingnashuie ? this.qiannianyingnashuie : null,
                QNXSSR: this.qiannianxiaoshoushouru ? this.qiannianxiaoshoushouru : null,
                ZCZB: this.zhuceziben ? this.zhuceziben : null,
                YEAR: this.nd ? this.nd : null,
                tm: new Date().getTime(),
                ENTERPRISE_ID: this.orgId,
                ENTERPRISE_TYPE: this.orgType,
                ENTERPRISE_NAME: this.orgName
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('保存成功')
                    this.showBtn = true
                } else if (res.result === 'repeat') {
                    this.$message.success('企业财税信息填报:年度重复')
                } else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        },
        getIsValid () {
            let reg = /^(\-|\+)?\d+(\.\d+)?$/
            if (this.nd === undefined) {
                this.$message.info('请选择年份')
                return false
            } else if (this.shangnianqiyesuodeshuiyingnashuie === '') {
                this.$message.info('上年企业所得税应纳税额不能为空！')
                return false
            } else if (!reg.test(this.shangnianqiyesuodeshuiyingnashuie)) {
                this.$message.info('请填写正确的上年企业所得税应纳税额！')
                return false
            } else if (this.shangnianjinglirun === '') {
                this.$message.info('上年净利润不能为空！')
                return false
            } else if (!reg.test(this.shangnianjinglirun)) {
                this.$message.info('请填写正确的上年净利润！')
                return false
            } else if (this.shangnianzengzhishuiyingnashuie === '') {
                this.$message.info('上年增值税应纳税额不能为空！')
                return false
            } else if (!reg.test(this.shangnianzengzhishuiyingnashuie)) {
                this.$message.info('请填写正确的上年增值税应纳税额！')
                return false
            } else if (this.shangnianjingyingxianjinjingliuliang === '') {
                this.$message.info('上年经营现金净流量不能为空！')
                return false
            } else if (!reg.test(this.shangnianjingyingxianjinjingliuliang)) {
                this.$message.info('请填写正确的上年经营现金净流量！')
                return false
            } else if (this.shangnianyingyeshouru === '') {
                this.$message.info('上年营业收入不能为空！')
                return false
            } else if (!reg.test(this.shangnianyingyeshouru)) {
                this.$message.info('请填写正确的上年营业收入！')
                return false
            } else if (this.shangnianzichanzongji === '') {
                this.$message.info('上年资产总计不能为空！')
                return false
            } else if (!reg.test(this.shangnianzichanzongji)) {
                this.$message.info('请填写正确的上年资产总计！')
                return false
            } else if (this.qiyeType === 'fw') {
                if (this.shangnianyingshouzhangkuan === '') {
                    this.$message.info('上年应收账款不能为空！')
                    return false
                } else if (!reg.test(this.shangnianyingshouzhangkuan)) {
                    this.$message.info('请填写正确的上年应收账款！')
                    return false
                } else if (this.shangnianfuzhaiheji === '') {
                    this.$message.info('上年负债合计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianfuzhaiheji)) {
                    this.$message.info('请填写正确的上年负债合计！')
                    return false
                } else if (this.shangnianxiaoshoushouru === '') {
                    this.$message.info('上年销售收入不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoshoushouru)) {
                    this.$message.info('请填写正确的上年销售收入！')
                    return false
                } else if (this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji === '') {
                    this.$message.info('上年销项发票中专票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji)) {
                    this.$message.info('请填写正确的上年销项发票中专票金额总计！')
                    return false
                } else if (this.shangnianxiaoxiangfapiaojinezongji === '') {
                    this.$message.info('上年销项发票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoxiangfapiaojinezongji)) {
                    this.$message.info('请填写正确的上年销项发票金额总计！')
                    return false
                } else if (this.qiannianyingshouzhangkuan === '') {
                    this.$message.info('前年应收账款不能为空！')
                    return false
                } else if (!reg.test(this.qiannianyingshouzhangkuan)) {
                    this.$message.info('请填写正确的前年应收账款！')
                    return false
                } else if (this.zhuceziben === '') {
                    this.$message.info('注册资本（元）不能为空！')
                    return false
                } else if (!reg.test(this.zhuceziben)) {
                    this.$message.info('请填写正确的注册资本（元）！')
                    return false
                } else {
                    return true
                }
            } else if (this.qiyeType === 'xw') {
                if (this.shangnianyingnashuie === '') {
                    this.$message.info('上年应纳税额不能为空！')
                    return false
                } else if (!reg.test(this.shangnianyingnashuie)) {
                    this.$message.info('请填写正确的上年应纳税额！')
                    return false
                } else if (this.shangniancaiwufeiyong === '') {
                    this.$message.info('上年财务费用不能为空！')
                    return false
                } else if (!reg.test(this.shangniancaiwufeiyong)) {
                    this.$message.info('请填写正确的上年财务费用！')
                    return false
                } else if (this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji === '') {
                    this.$message.info('上年进项发票中专票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji)) {
                    this.$message.info('请填写正确的上年进项发票中专票金额总计！')
                    return false
                } else if (this.shangnianjinxiangfapiaojinezongji === '') {
                    this.$message.info('上年进项发票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianjinxiangfapiaojinezongji)) {
                    this.$message.info('请填写正确的上年进项发票金额总计！')
                    return false
                } else if (this.shangnianxiaoshoufeiyong === '') {
                    this.$message.info('上年销售费用不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoshoufeiyong)) {
                    this.$message.info('请填写正确的上年销售费用！')
                    return false
                } else if (this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji === '') {
                    this.$message.info('上年销项发票中专票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji)) {
                    this.$message.info('请填写正确的上年销项发票中专票金额总计！')
                    return false
                } else if (this.shangnianxiaoxiangfapiaojinezongji === '') {
                    this.$message.info('上年销项发票金额总计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoxiangfapiaojinezongji)) {
                    this.$message.info('请填写正确的上年销项发票金额总计！')
                    return false
                } else if (this.qiannianyingnashuie === '') {
                    this.$message.info('前年应纳税额不能为空！')
                    return false
                } else if (!reg.test(this.qiannianyingnashuie)) {
                    this.$message.info('请填写正确的前年应纳税额！')
                    return false
                } else {
                    return true
                }
            } else if (this.qiyeType === 'zhizao') {
                if (this.shangnianfuzhaiheji === '') {
                    this.$message.info('上年负债合计不能为空！')
                    return false
                } else if (!reg.test(this.shangnianfuzhaiheji)) {
                    this.$message.info('请填写正确的上年负债合计！')
                    return false
                } else if (this.shangnianxiaoshoushouru === '') {
                    this.$message.info('上年销售收入不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoshoushouru)) {
                    this.$message.info('请填写正确的上年销售收入！')
                    return false
                } else if (this.shangnianxiaoshoufeiyong === '') {
                    this.$message.info('上年销售费用不能为空！')
                    return false
                } else if (!reg.test(this.shangnianxiaoshoufeiyong)) {
                    this.$message.info('请填写正确的上年销售费用！')
                    return false
                } else if (this.qiyechenglinianfen === '') {
                    this.$message.info('企业成立年份不能为空！')
                    return false
                } else if (this.qiannianxiaoshoushouru === '') {
                    this.$message.info('前年销售收入不能为空！')
                    return false
                } else if (!reg.test(this.qiannianxiaoshoushouru)) {
                    this.$message.info('请填写正确的前年销售收入！')
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        // 获取财税详情
        getCaiShuiDetail () {
            this.$store.dispatch('getCaiShuiDetail', { ENTERCAISHUI_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.shangnianqiyesuodeshuiyingnashuie = datas.SNQYSDSYNSE ? datas.SNQYSDSYNSE : ''
                    this.shangnianjinglirun = datas.SNJLR ? datas.SNJLR : ''
                    this.shangnianzengzhishuiyingnashuie = datas.SNZZSYNSE ? datas.SNZZSYNSE : ''
                    this.shangnianyingshouzhangkuan = datas.SNYSZK ? datas.SNYSZK : ''
                    this.shangnianyingnashuie = datas.SHYNSE ? datas.SHYNSE : ''
                    this.shangnianjingyingxianjinjingliuliang = datas.SNJYXJJLL ? datas.SNJYXJJLL : ''
                    this.shangnianyingyeshouru = datas.SNYYSR ? datas.SNYYSR : ''
                    this.shangnianfuzhaiheji = datas.SNFZHJ ? datas.SNFZHJ : ''
                    this.shangniancaiwufeiyong = datas.SNCWFY ? datas.SNCWFY : ''
                    this.shangnianzichanzongji = datas.SNZCZJ ? datas.SNZCZJ : ''
                    this.shangnianjinxiangfapiaozhongzhuanpiaojinezongji = datas.SNJXFPZZPJEZJ ? datas.SNJXFPZZPJEZJ : ''
                    this.shangnianjinxiangfapiaojinezongji = datas.SNJXFPJEZJ ? datas.SNJXFPJEZJ : ''
                    this.shangnianxiaoshoushouru = datas.SNXSSR ? datas.SNXSSR : ''
                    this.shangnianxiaoshoufeiyong = datas.SNXSFY ? datas.SNXSFY : ''
                    this.shangnianxiaoxiangfapiaozhongzhuanpiaojinezongji = datas.SNXXFPZZPJEZJ ? datas.SNXXFPZZPJEZJ : ''
                    this.shangnianxiaoxiangfapiaojinezongji = datas.SNXXFPJEZJ ? datas.SNXXFPJEZJ : ''
                    this.qiyechenglinianfen = datas.QICLNF ? datas.QICLNF : ''
                    this.qiannianyingshouzhangkuan = datas.QNYSZK ? datas.QNYSZK : ''
                    this.qiannianyingnashuie = datas.QNYNSE ? datas.QNYNSE : ''
                    this.qiannianxiaoshoushouru = datas.QNXSSR ? datas.QNXSSR : ''
                    this.zhuceziben = datas.ZCZB ? datas.ZCZB : ''
                    this.nd = datas.YEAR ? datas.YEAR : ''
                } else {
                    this.$message.error('系统异常，获取财税详情失败，请稍后重试！')
                }
            })
        },
        onSubmit () {
            let isValid = this.getIsValid()
            if (isValid) {
                this.$store.dispatch('genDiagnosisReport', { ENTERPRISE_ID: this.orgId, YEAR: this.nd, tm: new Date().getTime() }).then(res => {
                    if (res.result === 'success') {
                        this.$message.success('生成诊断报告成功！')
                    } else {
                        this.$message.error('系统异常，生成诊断报告失败，请稍后重试！')
                    }
                })
            }
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        // 获取企业信息
        this.getOrgDetail()
        // 企业人员信息-查询
            this.getPersonList()
        if (this.$route.query.id == undefined) {
            this.editStatus = false
        } else {
            this.id = this.$route.query.id
            this.editStatus = true
            // 获取财税详情
            this.getCaiShuiDetail()
        }
    }
}
</script>

<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .form-box{
            padding-top: 15px;
            .gd-item{
                display: flex;
                justify-content: space-between;
                margin-top: 24px;
                border-bottom: 1px solid #eaeaea;
                .ant-form-item{
                    width: 50%;
                }
                .gd-item-a{
                    width: 4%;
                    text-align: center;
                }
            }
            .gd-items{
                flex-wrap: wrap;
            }
            .gd-items:after {
                content:"";
                width:32%;
            }
            .gd-item:first-child{
                margin-top: 16px;
            }
        }

        .btn-item{
            margin-top: 15px;
            text-align: center;
        }
        .btn-items{
            text-align: center;
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
